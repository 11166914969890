// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accordion {
    margin: 10px 0;
    width: 100%;

    .accordion-header {
        padding: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        &:hover,
        &.open {
            background-color: #f9f9f9;
        }
    }

    .accordion-content {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease, padding 0.3s ease;

        &.open {
            max-height: 200px;
            /* Adjust based on your content */
            padding: 15px;
        }

        p {
            margin: 0;
        }
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Common/Accordion/accordion.scss"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,WAAW;;IAEX;QACI,aAAa;QACb,aAAa;QACb,8BAA8B;QAC9B,mBAAmB;QACnB,eAAe;;QAEf;;YAEI,yBAAyB;QAC7B;IACJ;;IAEA;QACI,aAAa;QACb,gBAAgB;QAChB,mDAAmD;;QAEnD;YACI,iBAAiB;YACjB,iCAAiC;YACjC,aAAa;QACjB;;QAEA;YACI,SAAS;QACb;IACJ;AACJ","sourcesContent":[".accordion {\n    margin: 10px 0;\n    width: 100%;\n\n    .accordion-header {\n        padding: 15px;\n        display: flex;\n        justify-content: space-between;\n        align-items: center;\n        cursor: pointer;\n\n        &:hover,\n        &.open {\n            background-color: #f9f9f9;\n        }\n    }\n\n    .accordion-content {\n        max-height: 0;\n        overflow: hidden;\n        transition: max-height 0.3s ease, padding 0.3s ease;\n\n        &.open {\n            max-height: 200px;\n            /* Adjust based on your content */\n            padding: 15px;\n        }\n\n        p {\n            margin: 0;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
