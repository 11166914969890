import React, { useState } from 'react';
import EventCard from './EventCard';
import EventModal from './EventModal';
import { Event } from './types';


const EventList: React.FC<{ events: Event[] }> = ({ events }) => {
    const [selectedEvent, setSelectedEvent] = useState<Event | undefined>();

    return (
        <div className="event-list">
            {events.map(event => (
                <EventCard key={event.id} event={event} onClick={() => setSelectedEvent(event)} />
            ))}
            <EventModal event={selectedEvent} onClose={() => setSelectedEvent(undefined)} />
        </div>
    );
};

export default EventList;
