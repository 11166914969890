import Button from "@mui/material/Button"
import Divider from "@mui/material/Divider"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import { Parallax } from "react-parallax";
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import HorizontalScroll from "../Common/HorizontalScroll/HorizontalScroll";
import { TeamMemberConfig } from "./config/teamMemberConfig";
import TeamMemberCard from "./TeamMemberCard";
import { FC } from "react";

interface OurTeamProps {
    hideTitle?: boolean;
}

export const OurTeam: FC<OurTeamProps> = (props) => {
    const { hideTitle } = props;

    return (
        <Parallax
            blur={{ min: -10, max: -1 }}
            strength={100}
            className="parallax-content secondary"
            style={{ padding: 0 }}
        >
            <Stack
                justifyContent='center'
                spacing='1.3em'
                alignItems='center'
                flexDirection='column'
                padding='2em 0px 0px 0px' >
                {!hideTitle &&
                    <>
                        <Typography variant='h4'>
                            Our Team
                        </Typography>
                        <Divider textAlign="left">
                            <SettingsSuggestIcon color='primary' />
                        </Divider>
                    </>
                }

            </Stack>
            <HorizontalScroll >
                <>
                    {
                        TeamMemberConfig.map(t => (
                            <TeamMemberCard
                                name={`${t.firstName} ${t.lastName.charAt(0).toUpperCase()}.`}
                                email={t.email}
                                role={t.role}
                                photoUrl={t.photoUrl}
                                linkedInUrl={t.linkedInUrl}
                            />
                        ))
                    }
                </>
            </HorizontalScroll>
            <Stack padding='0px 5%' justifyContent='flex-start' alignItems='center' flexDirection='column' style={{ width: '50%', fontWeight: 'light', height: '80%', display: 'flex' }}>
                <section >
                    {/* <Button color='primary' variant="text" onClick={() => { navigate('technologies') }}>Read more...</Button> */}
                </section>
            </Stack>
        </Parallax >
    )
}