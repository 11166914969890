// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.event-card {
    border: 1px solid #ccc;
    padding: 16px;
    margin-bottom: 8px;
    cursor: pointer;
    width: 100%;
    transition: background-color 0.3s ease;
    background: red;

    &:hover {
        background-color: #f9f9f9;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Common/Event/styles/eventCard.scss"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,aAAa;IACb,kBAAkB;IAClB,eAAe;IACf,WAAW;IACX,sCAAsC;IACtC,eAAe;;IAEf;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":[".event-card {\n    border: 1px solid #ccc;\n    padding: 16px;\n    margin-bottom: 8px;\n    cursor: pointer;\n    width: 100%;\n    transition: background-color 0.3s ease;\n    background: red;\n\n    &:hover {\n        background-color: #f9f9f9;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
