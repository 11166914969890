import React, { ReactNode, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './Modal.scss';
import { Container, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

interface ModalProps {
    title?: string;
    children: ReactNode;
    closeButton: () => void;
};

const ModalComponent: React.FC<ModalProps> = ({ title, children, closeButton }) => {
    const theme = useTheme();
    const matchMD = useMediaQuery(theme.breakpoints.down('md'));

    // Close the modal when clicking outside of the modal content
    const handleOutsideClick = (event: MouseEvent) => {
        if ((event.target as HTMLElement).classList.contains('modal-container')) {
            closeButton();
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    return ReactDOM.createPortal(
        <div className="modal-container">
            <div className={`modal-content ${matchMD ? 'smallScreen' : ''}`}>
                {title && <Typography variant='h6' textAlign='center' sx={{ paddingBottom: '1em', paddingTop: '1em' }}>{title}</Typography>}
                <button className="close-button" onClick={closeButton}>
                    &times;
                </button>
                <Container style={{ paddingBottom: '1.75em' }}>
                    {children}
                </Container>
            </div>
        </div>,
        document.body
    );
};

export default ModalComponent;