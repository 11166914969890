import { Typography } from "@mui/material";
import { FC } from "react";
import { useNavigate } from 'react-router-dom';

import './styles/footer.scss';

interface FooterLinkItemProps {
    title: string;
    linkUrl: string;
    isEmail?: boolean;
    isPhone?: boolean;
}

const FooterLinkItem: FC<FooterLinkItemProps> = (props) => {
    const { title, linkUrl, isEmail, isPhone } = props;
    const navigate = useNavigate();

    const handleClick = () => {
        if (isEmail) {
            window.location.href = `mailto:${linkUrl}`;
        } else if (isPhone) {
            window.location.href = `tel:${linkUrl}`;
        } else {
            navigate(linkUrl);
        }
    }

    return (
        <Typography variant="body2" className="footerSectionContentItem">
            <div onClick={handleClick}>{title}</div>
        </Typography>
    )
}

export default FooterLinkItem;