import React, { FC, ReactNode, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import './accordion.scss';

interface AccordionProps {
    title: string;
    children: ReactNode
}

const AccordionComponent: FC<AccordionProps> = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="accordion">
            <div className={`accordion-header ${isOpen ? 'open' : ''}`} onClick={toggleAccordion}>
                <div>{title}</div>
                {
                    isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                }
            </div>
            <div className={`accordion-content ${isOpen ? 'open' : ''}`}>
                {children}
            </div>
        </div>
    );
};

export default AccordionComponent;