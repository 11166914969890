import { Container } from "@mui/material"
import Navigation from "./Navigation";
import DarkLogo from "../../Assets/images/kivu-nova-dark.png";
import LightLogo from "../../Assets/images/Kivu-Nova-ligh.jpg";
import "./styles/header.scss"
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import PageThemeContext from "../../Contexts/pageThemeContext";
import { THEME } from "../../theme";
import { HOMEPAGE } from "../pageRoutes";
const classNames = require('classnames');

const Header = () => {
    const theme = useContext(PageThemeContext);
    const pageContainerClass = classNames('pageContainer', `page-theme-${theme}`);

    const navigate = useNavigate();
    return (
        <Container className={pageContainerClass} id="headerContainer">
            <Container
                className="headerLogoContainer"
                onClick={() => navigate(HOMEPAGE)}
            >
                <img src={theme === THEME.DARK ? LightLogo : DarkLogo} alt="Logo" width="100%" height="100%" />
            </Container>
            <Navigation />
        </Container>
    )
}

export default Header;