import { Container, List, ListItemButton, ListItemText, ListSubheader } from "@mui/material"
import { NavigationConfig } from "./types/navigationConfigType"
import { FC, useContext } from "react"
import React from "react"
import { useNavigate } from "react-router-dom";
import PageThemeContext from "../../Contexts/pageThemeContext";
const classNames = require('classnames');

interface NavigationMenuItemProps {
    navigations: NavigationConfig[];
    close: () => void;
}

const NavigationMenuItem: FC<NavigationMenuItemProps> = (props) => {
    const { navigations, close } = props;
    const navigate = useNavigate();
    const theme = useContext(PageThemeContext);
    const topNavigationMenuClass = classNames('navigationMenuItem', `page-theme-${theme}`);

    return (
        <Container className={topNavigationMenuClass}>
            <div className="poppover-tip"></div>
            <List>
                {
                    navigations.map(nav => (
                        <ListItemButton
                            onClick={() => {
                                if (nav.isExternal) {
                                    window.location.assign(nav.path || '');
                                } else {
                                    navigate(nav.path || '')
                                }
                                close();
                            }}
                            className="navigationMenuItemContent"
                        >
                            <ListItemText primary={nav.title} />
                        </ListItemButton>
                    ))
                }
            </List>
        </Container>
    );

}

export default NavigationMenuItem;