import { Container, Typography } from "@mui/material"
import { FC, ReactNode } from "react";

interface PageContentProps {
    children: ReactNode;
    styles?: Record<string, string>
};


export const PageContent: FC<PageContentProps> = (props) => {
    const { children, styles } = props;
    return (
        <Container sx={{
            minHeight: '38vh',
            background: 'rgb(226, 224, 224)',
            ...styles
        }}>
            {children}
        </Container>
    )
}

export default PageContent;