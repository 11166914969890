import { Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { FC } from "react";
import PageHeading from "../Common/PageHeading/PageHeading";
import PageContent from "../Common/PageContent/PageContent";

export const Mentorship: FC<{}> = () => {
    return (
        <section>
            <PageHeading titleText="Explore our mentorship program" />
            <PageContent>
                <section>

                </section>
            </PageContent>
        </section>
    )
}

export default Mentorship;