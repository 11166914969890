import React, { FC } from "react";
import { NavigationConfig } from "./types/navigationConfigType";
import { List, ListItemButton, ListItemText } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface NavigationItemProps {
    navConfig: NavigationConfig;
    closeMenu?: () => void
}


const NavigationItem: FC<NavigationItemProps> = (props) => {
    const { navConfig, closeMenu } = props;
    const navigate = useNavigate();

    return (
        <List sx={{ borderBottom: '1px solid #f6f6f6' }}>
            <ListItemButton className="topNavigationMenuContent" onClick={() => {
                if (navConfig.isExternal) {
                    window.location.assign(navConfig.path || '');
                } else {
                    navigate(navConfig.path || '')
                }
                // close side navigation on mobile phone
                if (closeMenu) {
                    closeMenu();
                }
            }}>
                <ListItemText primary={navConfig.title} />
            </ListItemButton>
        </List>
    )
}

export default NavigationItem;