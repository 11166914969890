import React, { useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import EventModal from './EventModal';
import { Event } from './types';


const localizer = momentLocalizer(moment);

const CalendarView: React.FC<{ events: Event[] }> = ({ events }) => {
    const [selectedEvent, setSelectedEvent] = useState<Event | undefined>();

    const handleSelectEvent = (event: Event) => {
        setSelectedEvent(event);
    };

    return (
        <div className="calendar-view">
            <Calendar
                localizer={localizer}
                events={events}
                onSelectEvent={handleSelectEvent}
            />
            <EventModal event={selectedEvent} onClose={() => setSelectedEvent(undefined)} />
        </div>
    );
};

export default CalendarView;
