import { NavigationConfig } from "../types/navigationConfigType";

export const NavigationConfigList: NavigationConfig[] = [
    {
        title: 'Our services',
        path: '/our-services',
    },
    {
        title: 'Technologies',
        path: 'technologies'
    },
    // {
    //     title: 'Join us',
    //     path: 'join-us'
    // },
    // {
    //     title: 'Hire us',
    //     path: 'hire-us'
    // },
    // {
    //     title: 'Events',
    //     isMultiLevel: true,
    //     subLinks: [
    //         {
    //             title: 'Kivu Tech corner',
    //             path: 'kivu-tech-corner'
    //         },
    //         {
    //             title: 'Mentorship',
    //             path: 'mentorship'
    //         },
    //         {
    //             title: 'Career connect',
    //             path: 'career-connect'
    //         }
    //     ]
    // },
    {
        title: 'Who we are',
        isMultiLevel: true,
        subLinks: [
            {
                title: 'About us',
                path: 'about-us'
            },
            {
                title: 'Meet our team',
                path: 'our-team'
            },
            // {
            //     title: 'Partners & Clients',
            //     path: 'partners-clients'
            // }
        ]
    }
]