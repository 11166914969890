// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pageWrapper {
    &.page-theme-dark {
        background: black !important;
        color: white !important;
    }

    &.page-theme-light {
        background: white !important;
        color: black !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI;QACI,4BAA4B;QAC5B,uBAAuB;IAC3B;;IAEA;QACI,4BAA4B;QAC5B,uBAAuB;IAC3B;AACJ","sourcesContent":[".pageWrapper {\n    &.page-theme-dark {\n        background: black !important;\n        color: white !important;\n    }\n\n    &.page-theme-light {\n        background: white !important;\n        color: black !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
