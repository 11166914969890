import React from 'react';
import { Card, CardContent, Container, Link, Typography } from '@mui/material';
import * as Icons from '@mui/icons-material';
import './styles/technologyCard.scss';

interface TechnologyCardProps {
    technology: { name: string; link: string; details: string };
}

const TechnologyCard: React.FC<TechnologyCardProps> = ({ technology }) => {

    return (
        <Card className="technology-card">
            <CardContent className="card-content">
                <Container className="card-front">
                    <Typography variant="h5" fontWeight='bold' component="div" className="technology-name">
                        {technology.name}
                    </Typography>
                </Container>
                <Container className="card-back">
                    <Typography variant="body2" className="technology-details">
                        {technology.details}
                    </Typography>
                    <Container>
                        <Link target="_blank" rel="noreferrer" className='technologyCardLink' underline='hover' href={technology.link}> Read more...</Link>
                    </Container>
                </Container>
            </CardContent>
        </Card>
    );
};

export default TechnologyCard;
