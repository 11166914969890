import { Container, Stack } from "@mui/material";
import Flyover from "../Common/Flyover/Flyover";
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from "react";
import SidenavigationMenu from "./SideNavigationMenu";


const Sidenavigation = () => {
    const [showFlyOver, setShowFlyOver] = useState<boolean>(false);
    return (
        <Container sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
        }}>
            <Stack
                onClick={() => {
                    setShowFlyOver(!showFlyOver);
                }}
            >
                <MenuIcon />
            </Stack>
            <Flyover isOpen={showFlyOver} closeMenu={() => { setShowFlyOver(!showFlyOver) }} >
                <Container>
                    <SidenavigationMenu closeMenu={() => { setShowFlyOver(!showFlyOver) }} />
                </Container>
            </Flyover>
        </Container>
    );
}

export default Sidenavigation;