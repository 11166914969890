import { Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { FC } from "react";
import PageHeading from "../Common/PageHeading/PageHeading";
import PageContent from "../Common/PageContent/PageContent";

export const PartnerClients: FC<{}> = () => {
    return (
        <section>
            <PageHeading titleText="Learn more about our partners and Clients" />
            <PageContent>
                <section>

                </section>
            </PageContent>
        </section>
    )
}

export default PartnerClients;