// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.parallax-content {
    background: "white";
    padding: 20px;
    position: "absolute";
    top: "50%";
    left: "50%";
    transform: "translate(-50%,-50%)";

    &.secondary {
        background-color: rgb(227, 224, 224);
    }

    &.homepageImg {
        padding-top: 0px;

        img {
            filter: brightness(0.6) !important;
        }
    }

    .openModalButton {
        padding: 15px 30px;
        font-size: 18px;
        color: #fff;
        background-color: rgba(255, 255, 255, 0.1);
        /* Light transparent background */
        border: 2px solid #fff;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease, color 0.3s ease;

        &:hover {
            background-color: rgba(255, 255, 255, 0.3);
            /* Slightly more opaque on hover */
            color: #000;
        }
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/homepage.scss"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,aAAa;IACb,oBAAoB;IACpB,UAAU;IACV,WAAW;IACX,iCAAiC;;IAEjC;QACI,oCAAoC;IACxC;;IAEA;QACI,gBAAgB;;QAEhB;YACI,kCAAkC;QACtC;IACJ;;IAEA;QACI,kBAAkB;QAClB,eAAe;QACf,WAAW;QACX,0CAA0C;QAC1C,iCAAiC;QACjC,sBAAsB;QACtB,kBAAkB;QAClB,eAAe;QACf,uDAAuD;;QAEvD;YACI,0CAA0C;YAC1C,kCAAkC;YAClC,WAAW;QACf;IACJ;AACJ","sourcesContent":[".parallax-content {\n    background: \"white\";\n    padding: 20px;\n    position: \"absolute\";\n    top: \"50%\";\n    left: \"50%\";\n    transform: \"translate(-50%,-50%)\";\n\n    &.secondary {\n        background-color: rgb(227, 224, 224);\n    }\n\n    &.homepageImg {\n        padding-top: 0px;\n\n        img {\n            filter: brightness(0.6) !important;\n        }\n    }\n\n    .openModalButton {\n        padding: 15px 30px;\n        font-size: 18px;\n        color: #fff;\n        background-color: rgba(255, 255, 255, 0.1);\n        /* Light transparent background */\n        border: 2px solid #fff;\n        border-radius: 5px;\n        cursor: pointer;\n        transition: background-color 0.3s ease, color 0.3s ease;\n\n        &:hover {\n            background-color: rgba(255, 255, 255, 0.3);\n            /* Slightly more opaque on hover */\n            color: #000;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
