import { Box, Button, Grid, Stack, TextareaAutosize, TextField, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { FC, useState, useEffect } from "react";
import PageContent from "../Common/PageContent/PageContent";
import YouTubeVideo from '../Common/YoutubeVideo/YoutubeVideo'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import ServiceCard from "./ServiceCard";
import { ServiceList } from "./constants/servicesData";
import Modal from '../Common/Modal/Modal';
import PageHeading from "../Common/PageHeading/PageHeading";


export const OurServices: FC<{}> = (props) => {
    const theme = useTheme();
    const matchMD = useMediaQuery(theme.breakpoints.down('md'));
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    return (
        <section>
            <PageHeading titleText="Our Services" />
            <PageContent styles={{ background: 'white' }}>
                <Stack
                    display='flex'
                    flexDirection={matchMD ? 'column' : 'row'}
                    paddingTop='1em'
                >
                    <Stack
                        display='flex'
                        flexDirection='column'
                        width={matchMD ? '100%' : '50%'}
                        padding='2em 0'
                        gap='1.5em'
                    >
                        <Typography fontSize='1.75em' gutterBottom variant="h6" fontWeight='regular'>We partner with you through every stage of your journey</Typography>
                        <Typography>Build your dedicated remote team, with full control and get your deliverable save up to 70% on employees costs.</Typography>
                        <Stack width='fit-content' padding='1em' marginTop='2em'>
                            <Button onClick={handleOpen} size="large" variant="contained">Book a chat with us</Button>
                            {
                                open && <Modal
                                    title="Book a chat with out team today!"
                                    closeButton={handleClose}>
                                    <Stack
                                        minHeight='150px'
                                        width='100%'
                                        justifyContent='center'
                                        alignItems='center'
                                        flexDirection='column'
                                        display='flex'
                                        spacing='2em'
                                    >
                                        <Container sx={{
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }}>
                                            <Stack
                                                spacing='2em'
                                                justifyContent='center'
                                                alignItems='center'
                                                padding='2em 0px 0px 0px'
                                                textAlign='center'
                                                width='100%'
                                            >
                                                <Box
                                                    component="form"
                                                    sx={{
                                                        '& > :not(style)': { m: 1, width: '100%' },
                                                    }}
                                                    noValidate
                                                    autoComplete="of"
                                                >
                                                    <Stack width='100%' flexDirection='row' gap={2}>
                                                        <TextField
                                                            error={false}
                                                            size='small'
                                                            id="outlined-error-helper-text"
                                                            label="Firstname"
                                                            variant="outlined"
                                                            helperText="Please enter your firstname"
                                                            required={true}
                                                            type='text'
                                                        />
                                                        <TextField
                                                            error={false}
                                                            size='small'
                                                            id="outlined-error-helper-text"
                                                            label="Lastname"
                                                            variant="outlined"
                                                            helperText="Please enter your lastname"
                                                            required={true}
                                                            type='text'
                                                        />
                                                    </Stack>
                                                    <TextField
                                                        error={false}
                                                        size='small'
                                                        id="outlined-error-helper-text"
                                                        label="Email"
                                                        variant="outlined"
                                                        helperText="Please enter a valid email"
                                                        required={true}
                                                        type='email'
                                                    />
                                                    <TextField
                                                        error={false}
                                                        size='small'
                                                        id="outlined-error-helper-text"
                                                        label="Phone number"
                                                        variant="outlined"
                                                        helperText="Please enter a valid phone number"
                                                        required={true}
                                                        type='tel'
                                                    />
                                                    <TextareaAutosize
                                                        maxRows={4}
                                                        aria-label="Send us an optional message"
                                                        placeholder="Send us an optional message"
                                                        minRows={3}
                                                    />
                                                </Box>
                                                <Button
                                                    variant="outlined"
                                                >
                                                    Send it...
                                                </Button>
                                            </Stack>
                                        </Container>
                                    </Stack>
                                </Modal>
                            }
                        </Stack>
                    </Stack>
                    <Stack width={matchMD ? '100%' : '50%'} padding='2em 0'>
                        <Container>
                            <YouTubeVideo videoId="dQw4w9WgXcQ" />
                        </Container>
                    </Stack>
                </Stack>
                <Typography fontSize='1.75em' gutterBottom variant="h4" align="center" sx={{ marginBottom: 3, marginTop: 3 }}>
                    What services do we offer?
                </Typography>
                <Typography variant="subtitle1" component="div" align="center" gutterBottom style={{ margin: '3em 0px', textAlign: 'center' }}>
                    At Kivunova, we offer mentorship programs for students, graduates, and aspiring engineers, helping them build strong foundations in tech. Additionally, we provide outsourcing services to institutions and businesses, delivering expert tech solutions to meet their unique needs.
                </Typography>

                <Grid container spacing={3}>
                    {ServiceList.map((service, index) => (
                        <Grid item xs={12} md={4} key={index}>
                            <ServiceCard title={service.title} icon={service.icon} serviceList={service.serviceList} />
                        </Grid>
                    ))}
                </Grid>
            </PageContent>
        </section>
    )
}

export default OurServices;