import React from 'react';
import { Event } from './types';

import './styles/eventModal.scss';

interface EventModalProps {
    event?: Event;
    onClose: () => void;
}

const EventModal: React.FC<EventModalProps> = ({ event, onClose }) => {
    if (!event) return null;

    return (
        <div className="event-modal">
            <div className="modal-content">
                <h2>{event.title}</h2>
                <p>{event.description}</p>
                <a href={`/events/${event.id}`}>Read more</a>
                <button onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default EventModal;
