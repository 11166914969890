import { Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { FC, useState } from "react";
import PageHeading from "../Common/PageHeading/PageHeading";
import PageContent from "../Common/PageContent/PageContent";
import EventList from "../Common/Event/EventList";
import CalendarView from "../Common/Event/CalendarView";
import { Event } from '../Common/Event/types';

export const KivuTechCorner: FC<{}> = () => {
    const [events, setEvents] = useState<Event[]>([]);
    return (
        <section>
            <PageHeading titleText="Explore and Join Kivu Tech Corner" />
            <PageContent>
                <section>
                    <EventList events={events} />
                    <CalendarView events={events} />
                </section>
            </PageContent>
        </section>
    )
}

export default KivuTechCorner;