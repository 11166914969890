import { Typography } from "@mui/material"
import { FC } from "react";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

interface PageHeadingProps {
    titleText: string;
}

export const PageHeading: FC<PageHeadingProps> = (props) => {
    const { titleText } = props;
    const theme = useTheme();
    const matchMD = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <section style={{
            width: '100%',
            height: '120px',
            background: '#232f3e',
            color: 'white',
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            padding: matchMD ? '0px' : '0px'
        }}>
            <Typography sx={{ padding: matchMD ? '0px 2em' : '0px', textAlign: 'center' }} variant={matchMD ? 'subtitle1' : 'h5'}>
                {titleText}
            </Typography>
        </section>
    )
}

export default PageHeading;