import { NavigationConfigList } from "./config/navigationConfig";
import NavigationItem from "./NavigationItem";
import Accordion from '../Common/Accordion/Accordion'
import { FC } from "react";
import { Container } from "@mui/material";

interface SideNavigationMenuProps {
    closeMenu: () => void;
}

const SidenavigationMenu: FC<SideNavigationMenuProps> = (props) => {
    const { closeMenu } = props;

    return (
        <Container sx={{
            width: '100%',
            padding: '0px',
            background: 'white',
            opacity: 1
        }}>
            {
                NavigationConfigList.map(navConfig => (
                    navConfig.subLinks ?
                        <div style={{ borderBottom: '1px solid #f6f6f6' }}>
                            <Accordion title={navConfig.title}>
                                {
                                    navConfig.subLinks.map(link => (
                                        <NavigationItem navConfig={link} closeMenu={closeMenu} />
                                    ))
                                }
                            </Accordion>
                        </div> :
                        <NavigationItem navConfig={navConfig} closeMenu={closeMenu} />
                ))
            }
        </Container>
    )
}

export default SidenavigationMenu;