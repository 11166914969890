// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.team-card {
    background-color: #f1f1f1;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-right: 10px;
    padding: 20px;
    text-align: center;
    flex: 0 0 auto;
    white-space: normal;
    transition: transform 0.3s ease-out;
    /* Smooth transition */
    width: 250px;
    /* Ensures a minimum width for the cards */
    margin: 0px 12.5px !important;
}

.team-card-photo {
    width: 60%;
    border-radius: 50%;
    object-fit: cover;

}

.team-card-info {
    padding-top: 10px;
}

.team-card-name {
    font-weight: bold;
}

.team-card-role {
    font-weight: lighter;
}

.team-card-icons {
    margin-top: 10px;
}

.team-card-icons a {
    margin: 0 5px;
    color: inherit;
    text-decoration: none;
}

.team-card-icons i {
    font-size: 1.2em;
}`, "",{"version":3,"sources":["webpack://./src/Components/OurTeam/styles/teamMemberCard.scss"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,sBAAsB;IACtB,kBAAkB;IAClB,kBAAkB;IAClB,aAAa;IACb,kBAAkB;IAClB,cAAc;IACd,mBAAmB;IACnB,mCAAmC;IACnC,sBAAsB;IACtB,YAAY;IACZ,0CAA0C;IAC1C,6BAA6B;AACjC;;AAEA;IACI,UAAU;IACV,kBAAkB;IAClB,iBAAiB;;AAErB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,cAAc;IACd,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".team-card {\n    background-color: #f1f1f1;\n    border: 1px solid #ccc;\n    border-radius: 8px;\n    margin-right: 10px;\n    padding: 20px;\n    text-align: center;\n    flex: 0 0 auto;\n    white-space: normal;\n    transition: transform 0.3s ease-out;\n    /* Smooth transition */\n    width: 250px;\n    /* Ensures a minimum width for the cards */\n    margin: 0px 12.5px !important;\n}\n\n.team-card-photo {\n    width: 60%;\n    border-radius: 50%;\n    object-fit: cover;\n\n}\n\n.team-card-info {\n    padding-top: 10px;\n}\n\n.team-card-name {\n    font-weight: bold;\n}\n\n.team-card-role {\n    font-weight: lighter;\n}\n\n.team-card-icons {\n    margin-top: 10px;\n}\n\n.team-card-icons a {\n    margin: 0 5px;\n    color: inherit;\n    text-decoration: none;\n}\n\n.team-card-icons i {\n    font-size: 1.2em;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
