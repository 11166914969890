// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flyover {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: white !important;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    transition: transform 0.3s ease-in-out;
    transform: translateX(100%);
    max-width: 100%;
    flex-direction: column;
    justify-content: flex-start;

    &.open {
        transform: translateX(0);
    }

    .overlay {
        position: relative;
        width: 100%;
        height: 80px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        max-width: 100%;

        #headerLogoContainer {
            width: fit-content !important;
            margin: 0 !important;
        }

    }

    .flyover-content {
        width: 100%;
        border-top: 1px solid gray;
        padding: 0;
        margin: 0;
        overflow-y: scroll;
    }

    a {
        color: white;
        text-decoration: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Common/Flyover/styles/index.scss"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,QAAQ;IACR,WAAW;IACX,YAAY;IACZ,4BAA4B;IAC5B,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,sCAAsC;IACtC,2BAA2B;IAC3B,eAAe;IACf,sBAAsB;IACtB,2BAA2B;;IAE3B;QACI,wBAAwB;IAC5B;;IAEA;QACI,kBAAkB;QAClB,WAAW;QACX,YAAY;QACZ,eAAe;QACf,aAAa;QACb,mBAAmB;QACnB,mBAAmB;QACnB,8BAA8B;QAC9B,eAAe;;QAEf;YACI,6BAA6B;YAC7B,oBAAoB;QACxB;;IAEJ;;IAEA;QACI,WAAW;QACX,0BAA0B;QAC1B,UAAU;QACV,SAAS;QACT,kBAAkB;IACtB;;IAEA;QACI,YAAY;QACZ,qBAAqB;IACzB;AACJ","sourcesContent":[".flyover {\n    position: fixed;\n    top: 0;\n    right: 0;\n    width: 100%;\n    height: 100%;\n    background: white !important;\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n    transition: transform 0.3s ease-in-out;\n    transform: translateX(100%);\n    max-width: 100%;\n    flex-direction: column;\n    justify-content: flex-start;\n\n    &.open {\n        transform: translateX(0);\n    }\n\n    .overlay {\n        position: relative;\n        width: 100%;\n        height: 80px;\n        cursor: pointer;\n        display: flex;\n        flex-direction: row;\n        align-items: center;\n        justify-content: space-between;\n        max-width: 100%;\n\n        #headerLogoContainer {\n            width: fit-content !important;\n            margin: 0 !important;\n        }\n\n    }\n\n    .flyover-content {\n        width: 100%;\n        border-top: 1px solid gray;\n        padding: 0;\n        margin: 0;\n        overflow-y: scroll;\n    }\n\n    a {\n        color: white;\n        text-decoration: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
