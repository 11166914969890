// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.youtube-container {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    /* 16:9 Aspect Ratio */
    overflow: hidden;
    background: black;

    .loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        font-size: 18px;
    }

    .video-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: opacity 0.5s ease-in-out;

        &.loaded {
            opacity: 1;
        }

        .video-player {
            width: 100%;
            height: 100%;

            iframe {
                width: 100%;
                height: 100%;
                border: 0;
            }
        }
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Common/YoutubeVideo/YoutubeVideo.scss"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,mBAAmB;IACnB,sBAAsB;IACtB,gBAAgB;IAChB,iBAAiB;;IAEjB;QACI,kBAAkB;QAClB,QAAQ;QACR,SAAS;QACT,gCAAgC;QAChC,YAAY;QACZ,eAAe;IACnB;;IAEA;QACI,kBAAkB;QAClB,MAAM;QACN,OAAO;QACP,WAAW;QACX,YAAY;QACZ,UAAU;QACV,oCAAoC;;QAEpC;YACI,UAAU;QACd;;QAEA;YACI,WAAW;YACX,YAAY;;YAEZ;gBACI,WAAW;gBACX,YAAY;gBACZ,SAAS;YACb;QACJ;IACJ;AACJ","sourcesContent":[".youtube-container {\n    position: relative;\n    width: 100%;\n    padding-top: 56.25%;\n    /* 16:9 Aspect Ratio */\n    overflow: hidden;\n    background: black;\n\n    .loader {\n        position: absolute;\n        top: 50%;\n        left: 50%;\n        transform: translate(-50%, -50%);\n        color: white;\n        font-size: 18px;\n    }\n\n    .video-wrapper {\n        position: absolute;\n        top: 0;\n        left: 0;\n        width: 100%;\n        height: 100%;\n        opacity: 0;\n        transition: opacity 0.5s ease-in-out;\n\n        &.loaded {\n            opacity: 1;\n        }\n\n        .video-player {\n            width: 100%;\n            height: 100%;\n\n            iframe {\n                width: 100%;\n                height: 100%;\n                border: 0;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
