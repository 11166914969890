// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    width: 100%;
    background: #f8f9fa !important;
    align-items: center;

    .footerContainer {
        display: flex;
        flex-direction: row !important;
        width: 90%;
        padding: 3em 0;
        justify-content: space-between;

        &.smallScreen {
            flex-direction: column !important;
            align-items: center;
        }

        .footerSection {
            display: flex;
            flex-direction: column;

            .footerSectionContent {
                display: flex;
                flex-direction: column;
                padding: 1em 0;

                .footerSectionContentItem {
                    cursor: pointer;
                    padding: 1.2px 0px;

                    &:hover {
                        color: rgb(63, 113, 163);
                    }
                }
            }
        }
    }


    .footerCopyRight {
        background: #232f3e;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        width: 100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Footer/styles/footer.scss"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,8BAA8B;IAC9B,mBAAmB;;IAEnB;QACI,aAAa;QACb,8BAA8B;QAC9B,UAAU;QACV,cAAc;QACd,8BAA8B;;QAE9B;YACI,iCAAiC;YACjC,mBAAmB;QACvB;;QAEA;YACI,aAAa;YACb,sBAAsB;;YAEtB;gBACI,aAAa;gBACb,sBAAsB;gBACtB,cAAc;;gBAEd;oBACI,eAAe;oBACf,kBAAkB;;oBAElB;wBACI,wBAAwB;oBAC5B;gBACJ;YACJ;QACJ;IACJ;;;IAGA;QACI,mBAAmB;QACnB,YAAY;QACZ,aAAa;QACb,uBAAuB;QACvB,mBAAmB;QACnB,YAAY;QACZ,WAAW;IACf;AACJ","sourcesContent":[".footer {\n    width: 100%;\n    background: #f8f9fa !important;\n    align-items: center;\n\n    .footerContainer {\n        display: flex;\n        flex-direction: row !important;\n        width: 90%;\n        padding: 3em 0;\n        justify-content: space-between;\n\n        &.smallScreen {\n            flex-direction: column !important;\n            align-items: center;\n        }\n\n        .footerSection {\n            display: flex;\n            flex-direction: column;\n\n            .footerSectionContent {\n                display: flex;\n                flex-direction: column;\n                padding: 1em 0;\n\n                .footerSectionContentItem {\n                    cursor: pointer;\n                    padding: 1.2px 0px;\n\n                    &:hover {\n                        color: rgb(63, 113, 163);\n                    }\n                }\n            }\n        }\n    }\n\n\n    .footerCopyRight {\n        background: #232f3e;\n        height: 60px;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        color: white;\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
