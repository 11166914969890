import React from 'react';
import { Event } from './types';

import './styles/eventCard.scss';


interface EventCardProps {
    event: Event;
    onClick: () => void;
}

const EventCard: React.FC<EventCardProps> = ({ event, onClick }) => (
    <div className="event-card" onClick={onClick}>
        <h3>{event.title}</h3>
        <p>{event.date.toDateString()}</p>
    </div>
);

export default EventCard;
