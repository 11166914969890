import React, { useState } from 'react';
import { Box, Button, Container, Divider, Stack, TextField, Typography } from "@mui/material";
import { Parallax } from 'react-parallax';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Modal from './Common/Modal/Modal';

import './homepage.scss';
import { useNavigate } from 'react-router-dom';
import WebhookIcon from '@mui/icons-material/Webhook';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import { OurTeam } from './OurTeam/OurTeam';
import HomeBg from '../Assets/images/home-bg.jpg';
import mockup1 from '../Assets/images/mockup1.png';
import mockup2 from '../Assets/images/mockup2.png';
import YouTubeVideo from './Common/YoutubeVideo/YoutubeVideo';



const Homepage = () => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const navigate = useNavigate();
    const theme = useTheme();
    const matchMD = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <section id="homepageContainer">
            <Parallax
                blur={0}
                bgImage={HomeBg}
                bgImageAlt="the cat"
                className="parallax-content primary homepageImg"
            >
                <Container style={{
                    height: matchMD ? '500px' : "700px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    grid: "10",
                    flexDirection: "column",
                    color: "white",
                    fontWeight: "bold",
                    textAlign: "center"
                }}>
                    <h2>Elevating Potentials, Igniting Futures!</h2>
                    <p style={{
                        fontFamily: 'monospace',
                        fontWeight: '100',
                        fontSize: 'small'
                    }}>We offer mentorship and resources, shaping innovation and fostering opportunities in the dynamic field of technology.</p>
                    <Container sx={{
                        padding: '2em 0px'
                    }}>
                        <button onClick={handleOpen} className='openModalButton'>Learn more..</button>
                        {
                            open && <Modal
                                title="Welcome to Kivu Nova"
                                closeButton={handleClose}>
                                <YouTubeVideo videoId="dQw4w9WgXcQ" />
                            </Modal>
                        }
                    </Container>
                </Container>
            </Parallax>
            <Parallax
                blur={{ min: -10, max: -1 }}
                strength={100}
                className="parallax-content secondary"
                style={{ padding: 0 }}
            >
                <Stack
                    justifyContent='center'
                    spacing='1.3em'
                    alignItems='center'
                    flexDirection='column'
                    padding='2em 0px 0px 0px'
                >
                    <Typography variant='h4'>
                        Our Services
                    </Typography>
                    <Divider textAlign="left">
                        <WebhookIcon color='primary' />
                    </Divider>
                </Stack>
                <Stack
                    style={{
                        padding: '3em 0px',
                        width: '100%'
                    }}
                    direction={matchMD ? 'column' : 'row'}
                    justifyContent='center'
                    alignItems='center'
                >
                    <Stack
                        padding='0px 5%'
                        justifyContent='flex-start'
                        alignItems='center'
                        flexDirection='column'
                        style={{
                            width: matchMD ? '85%' : '50%',
                            fontWeight: 'light',
                            height: '80%',
                            display: 'flex'
                        }}>
                        <p style={{
                            fontSize: 'large',
                            lineHeight: 1.5
                        }}>
                            At Kivunova, we empower youth by providing tech opportunities, mentorship, and real-world projects, paving the way for professional success. Our mission extends beyond education, as we strive to simplify lives through innovative technology solutions, fostering a brighter, more connected future for everyone. Join us in transforming potential into achievement and dreams into reality.
                        </p>
                        <section >
                            <Button
                                color='primary'
                                variant="text"
                                onClick={() => { navigate('our-services') }}
                            >
                                Read more...
                            </Button>
                        </section>
                    </Stack>
                    <Stack
                        spacing='2em'
                        flexDirection='column'
                        justifyContent='center'
                        alignItems='center'
                        style={{
                            width: matchMD ? '85%' : '50%',
                            color: '#232f3e',
                            fontWeight: 'bold',
                            height: '100%'
                        }}>
                        <img src={mockup1} />
                    </Stack>
                </Stack>
            </Parallax>
            <Parallax
                blur={{ min: -10, max: -1 }}
                strength={100}
                className="parallax-content secondary"
                style={{ padding: 0 }}
            >
                <Stack
                    justifyContent='center'
                    spacing='1.3em'
                    alignItems='center'
                    flexDirection='column'
                    padding='2em 0px 0px 0px' >
                    <Typography variant='h4'>
                        Technologies
                    </Typography>
                    <Divider textAlign="left">
                        <SettingsSuggestIcon color='primary' />
                    </Divider>
                </Stack>
                <Stack
                    style={{
                        padding: '3em 0px',
                        width: '100%'
                    }}
                    direction={matchMD ? 'column' : 'row'}
                    justifyContent='center'
                    alignItems='center'
                >
                    <Stack
                        spacing='2em'
                        flexDirection='column'
                        justifyContent='center'
                        alignItems='center'
                        style={{
                            width: matchMD ? '85%' : '50%',
                            color: '#232f3e',
                            fontWeight: 'bold',
                            height: '100%'
                        }}>
                        <img src={mockup2} />
                    </Stack>
                    <Stack
                        padding='0px 5%'
                        justifyContent='flex-start'
                        alignItems='center'
                        flexDirection='column'
                        style={{
                            width: matchMD ? '85%' : '50%',
                            fontWeight: 'light',
                            height: '80%', display: 'flex'
                        }}>
                        <p style={{
                            fontSize: 'large',
                            lineHeight: 1.5
                        }}>
                            At Kivunova, we empower youth by providing tech opportunities, mentorship, and real-world projects, paving the way for professional success. Our mission extends beyond education, as we strive to simplify lives through innovative technology solutions, fostering a brighter, more connected future for everyone. Join us in transforming potential into achievement and dreams into reality.
                        </p>
                        <section >
                            <Button
                                color='primary'
                                variant="text"
                                onClick={() => { navigate('technologies') }}
                            >
                                Read more...
                            </Button>
                        </section>
                    </Stack>
                </Stack>
            </Parallax>
            <Stack
                minHeight='150px'
                width='100%'
                justifyContent='center'
                alignItems='center'
                flexDirection='column'
                display='flex'
                spacing='2em'
                padding='4em 0'
            >
                <Container sx={{
                    width: '90%',
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <Typography variant={matchMD ? 'subtitle1' : 'h6'} textAlign='center' fontWeight='bold'>
                        Become a member of Kivunova Tech community today!
                    </Typography>
                    <Stack
                        spacing='2em'
                        justifyContent='center'
                        alignItems='center'
                        padding='2em 0px 0px 0px'
                        textAlign='center'
                    >
                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)': { m: 1, width: '25ch' },
                            }}
                            noValidate
                            autoComplete="of"
                        >
                            <TextField
                                error={false}
                                size='small'
                                id="outlined-error-helper-text"
                                label="Email"
                                variant="outlined"
                                helperText="Please enter a valid email"
                                required={true}
                                type='email'
                            />
                            <TextField
                                error={false}
                                size='small'
                                id="outlined-error-helper-text"
                                label="phone number"
                                variant="outlined"
                                helperText="Please enter a valid phone number"
                                type='tel'
                            />
                        </Box>
                        <Button
                            variant="outlined"
                        >
                            Get Started
                        </Button>
                    </Stack>
                </Container>
            </Stack>
            <OurTeam />
        </section>
    )
}

export default Homepage;