import React, { ReactNode, useContext, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';

import './styles/index.scss'; // Import CSS file for styling
import { Container } from '@mui/material';
import LightLogo from '../../../Assets/images/Kivu-Nova-ligh.jpg';
import DarkLogo from '../../../Assets/images/kivu-nova-dark.png';
import PageThemeContext from '../../../Contexts/pageThemeContext';
import { THEME } from '../../../theme';
import { useNavigate } from 'react-router-dom';

interface FlyoverProps {
    isOpen: boolean;
    closeMenu: () => void;
    children: ReactNode
}

const Flyover: React.FC<FlyoverProps> = ({ isOpen, closeMenu, children }) => {
    const theme = useContext(PageThemeContext);
    const navigate = useNavigate();
    return (
        <div className={`flyover ${isOpen ? 'open' : ''}`}>
            <Container className="overlay">
                <Container className="headerLogoContainer" onClick={() => navigate('/')}>
                    <img src={theme === THEME.DARK ? LightLogo : DarkLogo} alt="Logo" width="100%" height="100%" />
                </Container>
                <Container sx={{
                    width: 'fit-content',
                    margin: '0'
                }}>
                    <CloseIcon fontSize='large' onClick={closeMenu} />
                </Container>
            </Container>
            <Container className="flyover-content">
                {children}
            </Container>
        </div>
    );
};

export default Flyover;