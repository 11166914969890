// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.horizontal-scroll-container {
    overflow-x: auto;
    white-space: nowrap;
    padding: 20px;
    scroll-behavior: smooth;
    /* Smooth scrolling */
    -ms-overflow-style: none;
    /* Hide scrollbar for Internet Explorer and Edge */
    scrollbar-width: none;
    /* Hide scrollbar for Firefox */
    width: 100%;

    &::-webkit-scrollbar {
        display: none;
        /* Hide scrollbar for Chrome, Safari, and Opera */
    }

    .horizontal-scroll-content {
        display: inline-flex;
        transition: transform 0.1s ease-out;
        /* Smooth transition */
        justify-content: center;
        min-width: 100%;
        padding-right: 2em;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Common/HorizontalScroll/styles/horizontalScroll.scss"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,aAAa;IACb,uBAAuB;IACvB,qBAAqB;IACrB,wBAAwB;IACxB,kDAAkD;IAClD,qBAAqB;IACrB,+BAA+B;IAC/B,WAAW;;IAEX;QACI,aAAa;QACb,iDAAiD;IACrD;;IAEA;QACI,oBAAoB;QACpB,mCAAmC;QACnC,sBAAsB;QACtB,uBAAuB;QACvB,eAAe;QACf,kBAAkB;IACtB;AACJ","sourcesContent":[".horizontal-scroll-container {\n    overflow-x: auto;\n    white-space: nowrap;\n    padding: 20px;\n    scroll-behavior: smooth;\n    /* Smooth scrolling */\n    -ms-overflow-style: none;\n    /* Hide scrollbar for Internet Explorer and Edge */\n    scrollbar-width: none;\n    /* Hide scrollbar for Firefox */\n    width: 100%;\n\n    &::-webkit-scrollbar {\n        display: none;\n        /* Hide scrollbar for Chrome, Safari, and Opera */\n    }\n\n    .horizontal-scroll-content {\n        display: inline-flex;\n        transition: transform 0.1s ease-out;\n        /* Smooth transition */\n        justify-content: center;\n        min-width: 100%;\n        padding-right: 2em;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
