export const HOMEPAGE = '/';
export const OUR_SERVICES = '/our-services';
export const TECHNOLOGIES = '/technologies';
export const JOIN_US = '/join-us';
export const HIRE_US = '/hire-us';
export const KIVU_TECH_CORNER = '/kivu-tech-corner';
export const MENTORSHIP = 'mentorship';
export const CAREER_CONNECT = '/career-connect';
export const OUR_COMPANY = '/our-company';
export const MEET_OUR_TEAM = '/our-team';
export const PARTNERS_AND_CLIENTS = '/partners-clients';
export const OUR_MISSION = '/our-mission';
export const ABOUT_US = '/about-us';